import { BaseInput, I18n, InputAdornment, LoadingButton } from '@headless/ui'
import { useSubscribe } from '@hooks/Subscribe'
import useMediaQuery from '@mui/material/useMediaQuery'

import Logo from '@components/Logo'
import { StyledSubscribe, StyledTextField } from './styled'

function Subscribe() {
  const { control, errors, visible, handleFormSubmit } = useSubscribe()

  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  const logoProps = isMobile
    ? { width: 182 }
    : {
        width: 114
      }

  return (
    <>
      <Logo {...logoProps} />
      <StyledSubscribe>
        <p>Sign up for YESSA Style News</p>
        <form noValidate onSubmit={handleFormSubmit}>
          <StyledTextField>
            <BaseInput
              name="newsletter"
              label=""
              required
              fullWidth
              placeholder="Your Email Address"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadingButton
                      loading={false}
                      loadingIndicator="Loading…"
                      type="submit"
                    >
                      <I18n id="global.subscribe" />
                    </LoadingButton>
                  </InputAdornment>
                )
              }}
              control={control}
              errors={errors}
            />
          </StyledTextField>
        </form>
        {visible && <p className="success">Thank you for your subscription.</p>}
      </StyledSubscribe>
    </>
  )
}

export default Subscribe
