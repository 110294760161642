const data = [
  {
    content:
      '<a href="https://www.yessa.com/dresses-ys-en" class="level0"><span>Dresses</span></a>',
    id: 1
  },
  {
    content:
      '<a href="https://www.yessa.com/headpieces-ys-en" class="level0"><span>Headpieces</span></a>',
    id: 2
  },
  {
    content:
      '<a href="https://www.yessa.com/shoes-ys-en" class="level0"><span>Shoes</span></a>',
    id: 3
  },
  {
    content:
      '<a href="https://www.yessa.com/shawls-scarves-ys-en" class="level0"><span>Shawls</span></a>',
    id: 4
  },
  {
    content:
      '<a href="https://www.yessa.com/men-s-ys-en" class="level0"><span>Men\'s</span></a>',
    id: 5
  }
]

export default data
