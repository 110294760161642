/* eslint-disable @next/next/no-img-element */
import { gql } from '@apollo/client'
import { Link } from '@headless/ui'
import { useAutocomplete } from '@hooks/SearchBar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
  StyledAutoCompleteContainer,
  StyledAutoCompleteTitle,
  StyledNewProducts,
  StyledNewProductsContaienr,
  StyledSearchInfo,
  StyledSearchResult,
  StyledSeeAll,
  StyledTrending,
  StyledTrendingContainer
} from './styled'
import Suggestions from './suggestions'

const GET_AUTOCOMPLETE_RESULTS = gql`
  query getAutocompleteResults($inputText: String!) {
    # Limit results to first three.
    products(search: $inputText, currentPage: 1, pageSize: 4) {
      aggregations {
        label
        count
        attribute_code
        options {
          label
          value
        }
      }
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        id
        uid
        sku
        name
        small_image {
          url
        }
        url_key
        url_suffix
        price {
          regularPrice {
            amount {
              value
              currency
            }
          }
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            discount {
              amount_off
            }
          }
        }
      }
      page_info {
        total_pages
      }
      total_count
    }
  }
`

const Autocomplete = (props: any) => {
  const {
    setVisible,
    valid,
    visible,
    setIsSearchOpen,
    setSearchResult,
    searchValue
  } = props

  const talonProps = useAutocomplete({
    queries: {
      getAutocompleteResults: GET_AUTOCOMPLETE_RESULTS
    },
    valid,
    visible,
    setSearchResult,
    searchValue
  })

  const router = useRouter()

  const { displayResult, filters, messageType, products, resultCount } =
    talonProps

  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  const { formatMessage } = useIntl()

  const MESSAGES = new Map()
    .set(
      'ERROR',
      formatMessage({
        id: 'autocomplete.error',
        defaultMessage: 'An error occurred while fetching results.'
      })
    )
    .set(
      'LOADING',
      formatMessage({
        id: 'autocomplete.loading',
        defaultMessage: 'Fetching results...'
      })
    )
    .set(
      'PROMPT',
      formatMessage({
        id: 'autocomplete.prompt',
        defaultMessage: 'Search For A Product'
      })
    )
    .set(
      'EMPTY_RESULT',
      formatMessage({
        id: 'autocomplete.emptyResult',
        defaultMessage: 'No results were found.'
      })
    )
    .set('RESULT_SUMMARY', (_: any, count: any) =>
      formatMessage(
        {
          id: 'autocomplete.resultSummary',
          defaultMessage: '{count} items'
        },
        { count }
      )
    )
    .set(
      'INVALID_CHARACTER_LENGTH',
      formatMessage({
        id: 'autocomplete.invalidCharacterLength',
        defaultMessage: 'Search term must be at least three characters'
      })
    )

  const messageTpl = MESSAGES.get(messageType)
  const message =
    typeof messageTpl === 'function' ? messageTpl`${resultCount}` : messageTpl

  const destination = useMemo(() => {
    if (resultCount > 1) {
      return `/search?q=${searchValue}`
    }

    if (resultCount === 1) {
      const { items } = products
      return items.length > 0
        ? `/${items[0].url_key}${items[0].url_suffix}`
        : null
    }
  }, [products, resultCount, searchValue])

  const seeAll = useCallback(() => {
    if (destination) {
      router.push(destination)
    }
    setIsSearchOpen(false)
  }, [destination, router, setIsSearchOpen])

  const onViewResult = useCallback(
    (url_key: string, url_suffix: string) => {
      router.push(`${url_key}${url_suffix}`)
      setIsSearchOpen(false)
    },
    [setIsSearchOpen, router]
  )

  return (
    <StyledAutoCompleteContainer>
      <StyledTrending>
        <StyledAutoCompleteTitle>Trending</StyledAutoCompleteTitle>
        <StyledTrendingContainer>
          <Link href="/earrings-gn/ivory">
            <div className="img" />
            <span>Ivory</span>
          </Link>
          <Link href="/earrings-gn/gold">
            <div className="img" />
            <span>Gold</span>
          </Link>
          <Link href="/earrings-gn/red">
            <div className="img" />
            <span>Red</span>
          </Link>
          <Link href="/earrings-gn/coralpink">
            <div className="img" />
            <span>Pink</span>
          </Link>
          <Link href="/earrings-gn/skyblue">
            <div className="img" />
            <span>Blue</span>
          </Link>
          <Link href="/earrings-gn/lemon">
            <div className="img" />
            <span>Lemon</span>
          </Link>
        </StyledTrendingContainer>
      </StyledTrending>
      {!isMobile ? (
        <StyledNewProducts>
          <StyledAutoCompleteTitle>New Products</StyledAutoCompleteTitle>
          <StyledNewProductsContaienr>
            <div>
              <Link href="/gn-delicate-circle-earrings-eh23008cp.html">
                <img
                  src="https://magento.sweetv.com/media/catalog/product/cache/9608d96aeae0519fc3d5f6315f332cff/EH23008CP/1.jpg"
                  alt=""
                />
                <span>YESSA Delicate Circle Earrings</span>
              </Link>
            </div>
            <div>
              <Link href="/gn-butterfly-pearl-necklaces-xl23017cp67.html">
                <img
                  src="https://magento.sweetv.com/media/catalog/product/cache/9608d96aeae0519fc3d5f6315f332cff/XL23017CP67/1.jpg"
                  alt=""
                />
                <span>YESSA Butterfly Pearl Necklaces</span>
              </Link>
            </div>
          </StyledNewProductsContaienr>
        </StyledNewProducts>
      ) : null}
      <StyledSearchResult>
        <StyledAutoCompleteTitle>Search Result</StyledAutoCompleteTitle>
        {resultCount > 0 ? (
          <StyledSeeAll onClick={seeAll}>See All ({resultCount})</StyledSeeAll>
        ) : (
          <StyledSearchInfo>{message}</StyledSearchInfo>
        )}
        <Suggestions
          displayResult={displayResult}
          products={products || {}}
          filters={filters}
          setVisible={setVisible}
          visible={visible}
          onViewResult={onViewResult}
        />
      </StyledSearchResult>
    </StyledAutoCompleteContainer>
  )
}

export default Autocomplete
